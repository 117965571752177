<template>
	<el-dialog
		class="link_dialog"
		width="33.33333vw"
		:title="dialogTitle"
		:visible.sync="dialogVisible"
		:before-close="dialogBeforeClose"
	>
		<div>
			<el-form :model="form" ref="linkForm" :rules="rules" label-width="6.25vw">
				<el-form-item prop="title" label="环节名称：">
					<el-input
						v-model="form.title"
						placeholder="请输入流程名称"
            maxlength="50"
					></el-input>
				</el-form-item>
				<el-form-item prop="startTime" label="开始时间：">
					<el-time-picker
						v-model="form.startTime"
						type="date"
						format="HH:mm"
						value-format="HH:mm"
						:picker-options="startPicker"
						placeholder="请选择开始时间"
					></el-time-picker>
				</el-form-item>
				<el-form-item prop="endTime" label="结束时间：">
					<el-time-picker
						v-model="form.endTime"
						type="date"
						format="HH:mm"
						value-format="HH:mm"
						:picker-options="endPicker"
						placeholder="请选择结束时间"
					></el-time-picker>
				</el-form-item>
				<el-form-item prop="type" label="活动类型：">
					<el-select v-model="form.type" placeholder="请选择活动类型">
						<el-option
							v-for="link in linkType"
							:key="link.id"
							:label="link.name"
							:value="link.value"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div slot="footer">
			<el-button @click="dialogBeforeClose">取消</el-button>
			<el-button type="primary" @click="dialogBeforeSave()">保存</el-button>
			<el-button type="primary" @click="dialogBeforeSave('continue')">保存并继续</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
  props: {
    editDialogData: Object
  },
  data() {
    const endPickerValidator = (rule, value, callback) => {
      let { startTime } = this.form;
      if (startTime) {
        startTime = startTime.split(':').join('') * 1;
        value = value.split(':').join('') * 1;
        startTime > value && callback('结束时间不能早于开始时间');
      }
      callback();
    };
    const startPickerValidator = (rule, value, callback) => {
      let { endTime } = this.form;
      if (endTime) {
        endTime = endTime.split(':').join('') * 1;
        value = value.split(':').join('') * 1;
        endTime < value && callback('开始时间不能晚于结束时间');
      }
      callback();
    };
    return {
      dialogTitle: '添加环节',
      dialogVisible: false,
      rules: {
        title: [{ required: true, message: '请输入流程名称' }],
        startTime: [
          { required: true, message: '请选择开始时间' },
          { validator: startPickerValidator }
        ],
        endTime: [
          { required: true, message: '请选择结束时间' },
          { validator: endPickerValidator }
        ]
      },
      startPicker: {
        selectableRange: []
      },
      endPicker: {
        selectableRange: []
      },
      timeRange: [],
      form: {
        title: '',
        startTime: '',
        endTime: '',
        type: ''
      },
      originalData: {},
      formType: '',
      linkType: []
    };
  },
  created() {
    this.$api
      .getActivityDict()
      .then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        this.linkType = data;
      })
      .catch(() => {});
  },
  methods: {
    dialogBeforeClose() {
      this.dialogVisible = false;
      this.$refs.linkForm.resetFields();
      this.form.title = '';
      this.form.startTime = '';
      this.form.endTime = '';
      this.form.type = '';
    },
    dialogBeforeSave(status) {
      this.$refs['linkForm'].validate(v => {
        if (v) {
          if (status === 'continue') {
            this.$emit('status', status);
          }
          let data = Object.assign({}, this.form);
          let { title, startTime, endTime, type } = data;
          console.log(this.linkType);
          switch (this.formType) {
            case 'insert': {
              this.$emit('dialogData', {
                processName: title,
                startTime,
                endTime,
                activityType: type,
              });
              this.dialogBeforeClose();
              break;
            }
            case 'update': {
              this.originalData.processName = title;
              this.originalData.startTime = startTime;
              this.originalData.endTime = endTime;
              this.originalData.activityType = type;
              this.dialogBeforeClose();
              break;
            }
          }

        }
      });
    }
  },
  watch: {
    editDialogData(data) {
      this.dialogVisible = true;
      let { editType, timeRange } = data;
      this.formType = editType;
      this.startPicker.selectableRange = [];
      this.endPicker.selectableRange = [];
      console.log(editType, data);
      switch (editType) {
        case 'insert': {
          this.dialogTitle = '添加环节';
          break;
        }
        case 'update': {
          let { processName, startTime, endTime, activityType } = data.data;
          this.originalData = data.data;
          this.dialogTitle = '编辑环节';
          this.form.title = processName;
          this.form.startTime = startTime;
          this.form.endTime = endTime;
          this.form.type = activityType;
          break;
        }
      }

      this.startPicker.selectableRange = timeRange;
      this.endPicker.selectableRange = timeRange;
      this.timeRange = timeRange;
    }
  }
};
</script>

<style lang="scss">
.link_dialog {
	.el-input,
	.el-select {
		width: 100%;
	}
}
</style>
