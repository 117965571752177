<template>
  <div class="course-wrapper">
    <div class="course-wrapper-title">
      <span>{{ $route.params.id !== 'insert' ? '编辑模板' :'新建模版' }}</span>
    </div>

    <div class="course-wrapper-body">
      <el-form
        :model="templateData"
        :rules="templateFormRules"
        ref="templateFormRules"
        label-width="6.25vw"
      >
        <el-form-item prop="templateName" label="模版名称：">
          <el-input
            v-model="templateData.templateName"
            placeholder="请输入模板名称"
            :maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item prop="links" label="环节安排：">
          <div class="link_main">
            <div class="link_main_am">
              <!-- S 上午流程 -->
              <div v-for="link in links" :key="link.id">
                <div
                  v-if="!checkTimeSize(link.startTime)"
                  class="link_main_sub"
                >
                  <div class="lmp_time">
                    <p class="lmp_time_num">
                      {{ link.startTime }}-{{ link.endTime }}
                    </p>
                    <p class="lmp_time_m">上午</p>
                  </div>
                  <div class="lmp_divider"></div>
                  <div class="lmp_info"
                    @click="handleLink('update', { links, index: link.index })"
                  >
                    <p class="lmp_info_title">{{ link.processName }}</p>
                    <span
                      v-if="linkValue[link.activityType]"
                      class="lmp_info_type"
                      >{{ linkValue[link.activityType] }}</span
                    >
                    <div class="lmp_info_close" @click.stop="handleLinkDelete(link.index)"></div>
                  </div>
                </div>
              </div>
              <!-- E 上午流程 -->
            </div>

            <div class="link_main_pm">
              <!-- S 下午流程 -->
              <div v-for="link in links" :key="link.id">
                <div
                  v-if="checkTimeSize(link.startTime)"

                  class="link_main_sub"
                >
                  <div class="lmp_time">
                    <p class="lmp_time_num">
                      {{ link.startTime }}-{{ link.endTime }}
                    </p>
                    <p class="lmp_time_m">下午</p>
                  </div>
                  <div class="lmp_divider"></div>
                  <div class="lmp_info" @click="handleLink('update', { links, index: link.index })">
                    <p class="lmp_info_title">{{ link.processName }}</p>
                    <span
                      v-if="linkValue[link.activityType]"
                      class="lmp_info_type"
                      >{{ linkValue[link.activityType] }}
                    </span>
                    <div class="lmp_info_close" @click.stop="handleLinkDelete(link.index)"></div>
                  </div>
                </div>
              </div>
              <!-- E 下午流程 -->
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="link_button">
        <el-button
          class="link_button_insert"
          @click="handleLink('insear')"
          type="primary"
          icon="el-icon-plus"
          >添加环节</el-button
        >
      </div>
    </div>

    <div class="cw_link_template_footer" align="right">
      <el-button :loading="pageButtonLoading" @click="handleTemplateCancel">取消</el-button>
      <el-button :loading="pageButtonLoading" @click="handleTemplateSave" type="primary">确认</el-button>
    </div>

    <insertTemplateDialog
      :editDialogData="editDialogData"
      @dialogData="dialogButtonSave"
      @status="dialogSaveStatus"
      :key="insertTemplateDialogKey"
    />
  </div>
</template>

<script>
import insertTemplateDialog from './insertTemplateDialog.vue';
export default {
  name: 'insertTemplate',
  components: { insertTemplateDialog },
  inject: ['tagClose'],
  data() {
    return {
      templateData: {
        templateName: '',
        links: [],
      },
      links: [],
      // 添加/编辑环节数据
      editDialogData: {},
      linkType: [],
      linkValue: {},
      // 模版验证
      templateFormRules: {
        templateName: [
          {
            required: true,
            message: '请输入模版名称'
          }
        ],
        links: [
          {
            required: true,
            message: '请添加环节安排'
          }
        ]
      },
      insertTemplateDialogKey: '',
      pageButtonLoading: false, // 按钮loading
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to);
    console.log(from);
    if (to.params.id !== 'insert') {
      to.meta.title = '编辑模板';
    } else {
      to.meta.title = '新增模板';
    }
    next();
  },
  created() {
    let { id } = this.$route.params;

    if (id !== 'insert') {
      this.$api
        .searchTemplateDetails(id)
        .then(result => {
          let { code, data } = result.data;
          if (code) {
            return;
          }
          this.templateData = data.d;
          this.links = this.removeTimeEscond(data.list, [
            'startTime',
            'endTime'
          ]);
          this.linkSort();
          this.setLinkIndex();
        })
        .catch(err => {
          console.log(err);
        });
    }

    this.$api.getActivityDict()
      .then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        this.linkType = data;

        data.map(item => {
          this.linkValue[item.value] = item.name;
        });
      })
      .catch(error => {});
  },
  methods: {
    // 编辑环节
    handleLink(type, data) {
      let row = {};
      switch (type) {
        case 'insear': {
          this.editDialogData = {
            editType: 'insert',
            timeRange: this.linkTimeRange('insert')
          };
          break;
        }
        case 'update': {
          console.log(data);
          let { links, index } = data;
          let index1 = index - 1;
          let index2 = index + 1;

          if (index1 >= 0 && links[index1] && links[index1].endTime) {
            row.minTime = links[index1].endTime;
          }
          if (index2 && links[index2] && links[index2].startTime) {
            row.maxTime = links[index2].startTime;
          }
          row.editType = 'update';
          row.data = links[index];
          row.timeRange = this.linkTimeRange('update', row);
          this.editDialogData = row;
          break;
        }
      }
    },
    /**
     * 添加环节保存
     */
    dialogButtonSave(data) {
      let { processName, startTime, endTime, activityType } = data;
      this.links.push({
        processName,
        startTime,
        endTime,
        activityType
      });
      this.$nextTick(() => {
        this.linkSort();
        this.links.map((item, index) => {
          item.index = index;
          return item;
        });
        this.$refs['templateFormRules'].clearValidate('links');
      });
    },
    dialogSaveStatus(status) {
      this.handleLink('insear');
    },
    /**
     * 去除数据中开始时间和结束时间秒数
     * @param {array} data
     * @param {array} name
     */
    removeTimeEscond(data, name) {
      let time;
      data = data.map(item => {
        name.map(item1 => {
          time = item[item1].split(':');
          if (time.length === 3) {
            time.pop();
            time = time.join(':');
            item[item1] = time;
          }
        });
        return item;
      });
      return data;
    },
    /**
     * 验证环节开始时间，
     * @return {boolean} true：下午，false：上午
     */
    checkTimeSize(time) {
      time = time.split(':').join('') * 1;
      return time >= 1200;
    },
    /**
     * 上下午流程设置index
     */
    setLinkIndex() {
      this.links.map((item, index) => {
        item.index = index;
        return item;
      });
    },
    /**
     * 删除流程
     * @param {*}
     * @return {*}
     */
    handleLinkDelete(index) {
      let links = this.links;
      links.splice(index, 1);
      links.map((item, index) => {
        item.index = index;
        return item;
      });
    },
    /**
     * 模版保存
     */
    handleTemplateSave() {
      this.templateData.links = this.links;
      console.log(this.templateData);
      this.$refs['templateFormRules'].validate(v => {
        console.log(v);
        if (!v) {
          return;
        }
        this.pageButtonLoading = true;
        let { id, templateName } = this.templateData;
        let data = {
          id,
          templateName,
          processes: this.links
        };
        let fnName = '';
        fnName = id ? 'updateLink' : 'insertLink';
        this.$api[fnName](data)
          .then(result => {
            this.pageButtonLoading = false;
            let { code, data, msg } = result.data;
            if (code) {
              this.$message.error(msg);
              return;
            }
            this.$message.success(data);
            this.tagClose(this.$route.fullPath);
            this.$nextTick(() => {
              this.handleTemplateCancel();
            });
          })
          .catch(error => {
            this.pageButtonLoading = false;
            console.log(error);
          });
      });

    },
    /**
     * 按钮-页面取消
     */
    handleTemplateCancel() {
      this.tagClose(this.$route.fullPath);
      this.$router.push('/dailyProcessList');
    },
    /**
     * 环节排序
     */
    linkSort() {
      let links = this.links.sort((a, b) => {
        a = a.startTime.split(':').join('');
        b = b.startTime.split(':').join('');
        return a - b;
      });
      return links;
    },
    /**
     * 可获取时间范围
     * @param {string} handleType 状态
     */
    linkTimeRange(handleType, data) {
      let range = [];
      let link;
      switch (handleType) {
        case 'insert': {
          this.links.forEach((item, index) => {
            if (index === 0) {
              if (this.timeToNumber(item.startTime) !== 0) {
                range.push(`00:00:00 - ${ item.startTime }:00`);
              }
            }
            link = this.links[index + 1];
            if (link) {
              if (this.timeToNumber(item.endTime) !== this.timeToNumber(link.startTime)) {
                range.push(`${ item.endTime }:00 - ${ link.startTime }:00`);
              }
            } else {
              range.push(`${ item.endTime }:00 - 23:59:00`);
            }
          });
          break;
        }
        case 'update': {
          let { minTime, maxTime } = data;
          if (minTime && maxTime) {
            range.push(`${ minTime }:00 - ${ maxTime }:00`);
          } else if (minTime) {
            range.push(`${ minTime }:00 - 23:59:00`);
          } else if (maxTime) {
            range.push(`00:00:00 - ${ maxTime }:00`);
          }
          break;
        }
      }
      return range;
    },
    /**
     * 时间转数字 10:00 -> 1000
     */
    timeToNumber(str) {
      return str.split(':').join('') * 1;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/common_c.scss";
.link_button {
  text-align: center;
  .link_button_insert {
    border-radius: 100px;
  }
}
.link_main {
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  .link_main_am {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    .lmp_time {
      .lmp_time_m {
        &::before {
          background: url(../../../assets/img/icon_insertTemplate_am.png);
        }
      }
    }
  }
  .link_main_pm {
    display: flex;
    flex-direction: column;
    .lmp_time {
      .lmp_time_m {
        &::before {
          background: url(../../../assets/img/icon_insertTemplate_pm.png);
        }
      }
    }
  }
  .link_main_sub {
    display: flex;
  }
  .lmp_time {
    width: 130px;
    text-align: right;
    .lmp_time_num {
      font-size: 18px;
    }
    .lmp_time_m {
      color: #999;
      &::before {
        display: inline-block;
        width: 28px;
        height: 16px;
        content: "";
      }
    }
  }
  .lmp_divider {
    padding: 0 20px;
    text-align: center;
    &::before {
      display: block;
      content: "";
      width: 14px;
      height: 14px;
      background: #ffffff;
      border: 3px solid #d7d7d7;
      border-radius: 50%;
    }
    &::after {
      display: inline-block;
      content: "";
      width: 0;
      height: 100%;
      border: 1px solid #f1f1f1;
    }
  }
  .lmp_info {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px 20px;
    width: 338px;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0 3px 16px #14142940;
    border-radius: 10px;
    cursor: pointer;
    .lmp_info_title {
      color: #00000085;
      font-size: 20px;
    }
    .lmp_info_type {
      width: 100px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      color: #5f59f0;
      text-align: center;
      background: #dfe4ff;
      border-radius: 40px;
    }
    .lmp_info_close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 12px;
      height: 12px;
      background: url(../../../assets/img/icon_insertTemplate_close.png)
        no-repeat center;
      cursor: pointer;
    }
  }
}
.course-wrapper {
  .cw_link_template_footer {
    padding: 30px 60px;
    border-top: 1px solid #ececec;
  }
}
</style>
